import { configureStore } from "@reduxjs/toolkit";
import { userReducer, passwordReducer } from "./Reducers/User";
import {
  ProjectedCashReducer,
  CashPositionReducer,
  KeyMetricsReducer,
  KPIReducer,
  InsightsReducer,
} from "./Reducers/Update";

export const store = configureStore({
  reducer: {
    user: userReducer,

    passwords: passwordReducer,
    ProjectedCash: ProjectedCashReducer,
    CashPosition: CashPositionReducer,
    KeyMetrics: KeyMetricsReducer,
    KPI: KPIReducer,
    Insights: InsightsReducer,
  },

  devTools: process.env.NODE_ENV !== "production",
});
