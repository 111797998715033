import { createReducer } from "@reduxjs/toolkit";

const InitialState = {
  //value: 0,
  //description: "Revenue",
  //type: "Revenue",
  //KeyMetricValue : 0,
  //}],
};

const insights = {
  Revenue: {
    good: "",
    bad: "",
  },
  Expenses: {
    good: "",
    bad: "",
  },
  Competitors: {
    good: "",
    bad: "",
  },
  Operations: {
    good: "",
    bad: "",
  },
  Growth: {
    good: "",
    bad: "",
  },
  Marketing: {
    good: "",
    bad: "",
  },
  Tech: {
    good: "",
    bad: "",
  },
  Product: {
    good: "",
    bad: "",
  },
  other: [
    {
      questionLabel: "",
      Thegoodquestion: "",
      Thebadquestion: "",
    },
  ],
};

export const ProjectedCashReducer = createReducer(InitialState, {
  UPDATE_PROJCASH_CURRENT_MONTH: (state, action) => {
    state.porjCashCurrentMonth = action.payload;
  },
  UPDATE_PROJCASH_NEXT_MONTH: (state, action) => {
    state.projCashNextMonth = action.payload;
  },
  UPDATE_PROJCASH_MONTH_AFTER: (state, action) => {
    state.projCashMonthAfter = action.payload;
  },
  UPDATE_PROJCASH_MONTH_AFTER_THAT: (state, action) => {
    state.projCashMonthAfterThat = action.payload;
  },
  UPDATE_NEXT_MONTH_BURN: (state, action) => {
    state.nextMonthBurn = action.payload;
  },
  UPDATE_MONTH_AFTER_BURN: (state, action) => {
    state.monthAfterBurn = action.payload;
  },
  UPDATE_MONTH_AFTER_THAT_BURN: (state, action) => {
    state.monthAfterThatBurn = action.payload;
  },
});
export const CashPositionReducer = createReducer(InitialState, {
  UPDATE_CASHPOS_CASH_AVAILABLE: (state, action) => {
    state.CashPosCashAvailable = action.payload;
  },
  UPDATE_CASHPOS_PENDING_INVESTOR_PAYMENTS: (state, action) => {
    state.CashPosPendingInvestorPayments = action.payload;
  },
  UPDATE_CASHPOS_COMMITTED_FUNDING: (state, action) => {
    state.CashPosCommittedFunding = action.payload;
  },
  UPDATE_CASHPOS_AVAILABLE_BRIDGE_INVESTMENT: (state, action) => {
    state.CashPosAvailableBridgeInvestment = action.payload;
  },
});

export const KeyMetricsReducer = createReducer(InitialState, {
  UPDATE_KEY_METRICS: (state, action) => {
    state.keyMetrics = action.payload;
  },
  NEW_KEY_METRICS: (state, action) => {
    let keyArray = action.payload.map((item) => {
      return {
        label: item.label,
        value: item.value,
        description: item.description,
        type: item.type,
      };
    });
    state.keyMetrics = keyArray;
  },
});

export const KPIReducer = createReducer(InitialState, {
  UPDATE_KPI: (state, action) => {
    state.KPI = action.payload;
  },
  NEW_KPI: (state, action) => {
    let kpiArray = action.payload.map((item) => {
      return {
        label: item.label,
        value: item.value,
        description: item.description,
        type: item.type,
        projprevMonthKPI: item.projCurrentMonthKPI,
        actualprevmonthKPI: item.actualCurrentmonthKPI,
        momPrev: item.momcurrentmonthKPI,
        amaPrev: item.amacurrentmonthKPI,
      };
    });

    state.KPI = kpiArray;
  },
});

export const InsightsReducer = createReducer(insights, {
  UPDATE_Revenue_good: (state, action) => {
    state.Revenue.good = action.payload;
  },
  UPDATE_Revenue_bad: (state, action) => {
    state.Revenue.bad = action.payload;
  },

  UPDATE_Expenses_good: (state, action) => {
    state.Expenses.good = action.payload;
  },
  UPDATE_Expenses_bad: (state, action) => {
    state.Expenses.bad = action.payload;
  },

  UPDATE_Competitors_good: (state, action) => {
    state.Competitors.good = action.payload;
  },
  UPDATE_Competitors_bad: (state, action) => {
    state.Competitors.bad = action.payload;
  },

  UPDATE_Operations_good: (state, action) => {
    state.Operations.good = action.payload;
  },
  UPDATE_Operations_bad: (state, action) => {
    state.Operations.bad = action.payload;
  },

  UPDATE_Growth_good: (state, action) => {
    state.Growth.good = action.payload;
  },
  UPDATE_Growth_bad: (state, action) => {
    state.Growth.bad = action.payload;
  },

  UPDATE_Marketing_good: (state, action) => {
    state.Marketing.good = action.payload;
  },
  UPDATE_Marketing_bad: (state, action) => {
    state.Marketing.bad = action.payload;
  },

  UPDATE_Tech_good: (state, action) => {
    state.Tech.good = action.payload;
  },
  UPDATE_Tech_bad: (state, action) => {
    state.Tech.bad = action.payload;
  },

  UPDATE_Product_good: (state, action) => {
    state.Product.good = action.payload;
  },
  UPDATE_Product_bad: (state, action) => {
    state.Product.bad = action.payload;
  },

  UPDATE_other: (state, action) => {
    state.other = action.payload;
  },
  NEW_other: (state, action) => {
    let otherArray = action.payload.map((item) => {
      return {
        questionLabel: item.questionLabel,
      };
    });
    state.other = otherArray;
  },
});
