import "./styles/style.scss";
import React, { Suspense, useEffect } from "react";

import Loader from "./layouts/loader/Loader";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";

// import FullLayout from "./layouts/FullLayout";
// import Login from "./pages/login/login";

import {
  FullLayout,
  Login,
  SignUp,
  Portfolio,
  Nama,
  Status,
  Update,
  YearMonth,
  CompanyYearMonth,
  Settings,
  PortfolioSettings,
  ForgotPassword,
  Edit,
  Company,
} from "./routes/Router";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const { role, isAuthenticated } = useSelector((state) => state.user);

  return (
    <Suspense fallback={<Loader />}>
      <FullLayout>
        <Routes>
          <Route
            path="/"
            element={
              isAuthenticated === true ? (
                (role === "superAdmin" && (
                  <Navigate to="/nama" replace={true} />
                )) ||
                (role === "systemAdmin" && (
                  <Navigate to="/nama" replace={true} />
                )) ||
                (role === "companyAdmin" && (
                  <Navigate to="/portfolio" replace={true} />
                )) ||
                (role === "User" && (
                  <Navigate to="/portfolio" replace={true} />
                )) ||
                (role === "Editor" && (
                  <Navigate to="/portfolio" replace={true} />
                ))
              ) : (
                <Navigate to="/login" replace={true} />
              )
            }
          />

          <Route path="/forgot/password" element={<ForgotPassword />} />

          <Route
            path="/login"
            element={
              isAuthenticated === true ? (
                (role === "systemAdmin" && (
                  <Navigate to="/nama" replace={true} />
                )) ||
                (role === "superAdmin" && (
                  <Navigate to="/nama" replace={true} />
                )) ||
                (role === "companyAdmin" && (
                  <Navigate to="/portfolio" replace={true} />
                )) ||
                (role === "User" && (
                  <Navigate to="/portfolio" replace={true} />
                )) ||
                (role === "Editor" && (
                  <Navigate to="/portfolio" replace={true} />
                ))
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/signup"
            element={
              isAuthenticated === true ? (
                (role === "superAdmin" && (
                  <Navigate to="/nama" replace={true} />
                )) ||
                (role === "systemAdmin" && (
                  <Navigate to="/nama" replace={true} />
                )) ||
                (role === "companyAdmin" && (
                  <Navigate to="/portfolio" replace={true} />
                )) ||
                (role === "User" && (
                  <Navigate to="/portfolio" replace={true} />
                )) ||
                (role === "Editor" && (
                  <Navigate to="/portfolio" replace={true} />
                ))
              ) : (
                <SignUp />
              )
            }
          />

          {/* <Route
            path="/admin-signup"
            element={
              isAuthenticated === true ? (
                (role === "superAdmin" && (
                  <Navigate to="/nama" replace={true} />
                )) ||
                (role === "systemAdmin" && (
                  <Navigate to="/nama" replace={true} />
                )) ||
                (role === "companyAdmin" && (
                  <Navigate to="/portfolio" replace={true} />
                )) ||
                (role === "User" && <Navigate to="/portfolio" replace={true} />)
              ) : (
                <AdminSignUp />
              )
            }
          /> */}
          <Route
            path="/portfolio"
            element={
              isAuthenticated === true ? (
                (role === "superAdmin" && (
                  <Navigate to="/nama" replace={true} />
                )) ||
                (role === "systemAdmin" && (
                  <Navigate to="/nama" replace={true} />
                )) ||
                (role === "companyAdmin" && <Portfolio />) ||
                (role === "User" && <Portfolio />) ||
                (role === "Editor" && <Portfolio />)
              ) : (
                <Navigate to="/login" replace={true} />
              )
            }
          />
          <Route
            path="/portfolio/settings"
            element={
              isAuthenticated === true ? (
                (role === "superAdmin" && (
                  <Navigate to="/nama" replace={true} />
                )) ||
                (role === "systemAdmin" && (
                  <Navigate to="/nama" replace={true} />
                )) ||
                (role === "companyAdmin" && <PortfolioSettings />) ||
                (role === "User" && (
                  <Navigate to="/portfolio" replace={true} />
                )) ||
                (role === "Editor" && (
                  <Navigate to="/portfolio" replace={true} />
                ))
              ) : (
                <Navigate to="/login" replace={true} />
              )
            }
          />
          <Route
            path="/portfolio/update"
            element={
              isAuthenticated === true ? (
                (role === "superAdmin" && (
                  <Navigate to="/nama" replace={true} />
                )) ||
                (role === "systemAdmin" && (
                  <Navigate to="/nama" replace={true} />
                )) ||
                (role === "companyAdmin" && <Update />) ||
                (role === "Editor" && <Update />) ||
                (role === "User" && <Navigate to="/portfolio" replace={true} />)
              ) : (
                <Login />
              )
            }
          />

          <Route
            path="/portfolio/:companyName/:year/:month"
            element={
              isAuthenticated === true ? (
                (role === "superAdmin" && (
                  <Navigate to="/nama" replace={true} />
                )) ||
                (role === "systemAdmin" && (
                  <Navigate to="/nama" replace={true} />
                )) ||
                (role === "companyAdmin" && <YearMonth />) ||
                (role === "User" && <YearMonth />) ||
                (role === "Editor" && <YearMonth />)
              ) : (
                <Navigate to="/login" replace={true} />
              )
            }
          />
          <Route
            path="/portfolio/:companyName/:year/:month/edit"
            element={
              isAuthenticated === true ? (
                (role === "superAdmin" && (
                  <Navigate to="/nama" replace={true} />
                )) ||
                (role === "systemAdmin" && (
                  <Navigate to="/nama" replace={true} />
                )) ||
                (role === "companyAdmin" && <Edit />) ||
                (role === "Editor" && <Edit />) ||
                (role === "User" && <Navigate to="/portfolio" replace={true} />)
              ) : (
                <Navigate to="/login" replace={true} />
              )
            }
          />
          <Route
            path="/nama"
            element={
              isAuthenticated === true ? (
                (role === "systemAdmin" && <Nama />) ||
                (role === "superAdmin" && <Nama />) ||
                (role === "companyAdmin" && (
                  <Navigate to="/portfolio" replace={true} />
                )) ||
                (role === "User" && (
                  <Navigate to="/portfolio" replace={true} />
                )) ||
                (role === "Editor" && (
                  <Navigate to="/portfolio" replace={true} />
                ))
              ) : (
                <Navigate to="/login" replace={true} />
              )
            }
          />

          <Route
            path="/nama/status"
            element={
              isAuthenticated === true ? (
                (role === "superAdmin" && <Status />) ||
                (role === "systemAdmin" && <Status />) ||
                (role === "companyAdmin" && (
                  <Navigate to="/portfolio" replace={true} />
                )) ||
                (role === "User" && (
                  <Navigate to="/portfolio" replace={true} />
                )) ||
                (role === "Editor" && (
                  <Navigate to="/portfolio" replace={true} />
                ))
              ) : (
                <Navigate to="/login" replace={true} />
              )
            }
          />
          <Route
            path="/nama/:companyName"
            element={
              isAuthenticated === true ? (
                (role === "systemAdmin" && <Company />) ||
                (role === "superAdmin" && <Company />) ||
                (role === "companyAdmin" && (
                  <Navigate to="/portfolio" replace={true} />
                )) ||
                (role === "User" && (
                  <Navigate to="/portfolio" replace={true} />
                )) ||
                (role === "Editor" && (
                  <Navigate to="/portfolio" replace={true} />
                ))
              ) : (
                <Navigate to="/login" replace={true} />
              )
            }
          />
          <Route
            path="/nama/:companyName/:year/:month"
            element={
              isAuthenticated === true ? (
                (role === "systemAdmin" && <CompanyYearMonth />) ||
                (role === "superAdmin" && <CompanyYearMonth />) ||
                (role === "companyAdmin" && (
                  <Navigate to="/portfolio" replace={true} />
                )) ||
                (role === "User" && (
                  <Navigate to="/portfolio" replace={true} />
                )) ||
                (role === "Editor" && (
                  <Navigate to="/portfolio" replace={true} />
                ))
              ) : (
                <Navigate to="/login" replace={true} />
              )
            }
          />
          <Route
            path="/nama/settings"
            element={
              isAuthenticated === true ? (
                (role === "systemAdmin" && (
                  <Navigate to="/nama" replace={true} />
                )) ||
                (role === "superAdmin" && <Settings />) ||
                (role === "companyAdmin" && (
                  <Navigate to="/portfolio" replace={true} />
                )) ||
                (role === "User" && (
                  <Navigate to="/portfolio" replace={true} />
                )) ||
                (role === "Editor" && (
                  <Navigate to="/portfolio" replace={true} />
                ))
              ) : (
                <Navigate to="/login" replace={true} />
              )
            }
          />
        </Routes>
      </FullLayout>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Suspense>
  );
}

export default App;
