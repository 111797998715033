import { createReducer } from "@reduxjs/toolkit";

const initialState = {};
export const userReducer = createReducer(initialState, {
  LoginRequest: (state) => {
    state.loading = true;
  },
  LoginSuccess: (state, action) => {
    state.loading = false;
    state.user = action.payload;
    state.isAuthenticated = true;
    state.role = action.payload.role;
  },
  LoginFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.isAuthenticated = false;
    state.role = null;
  },

  RegisterRequest: (state) => {
    state.loading = true;
  },
  RegisterSuccess: (state, action) => {
    state.loading = false;
    state.user = action.payload.user;

    state.message = action.payload.message;
    state.isAuthenticated = true;
    state.role = action.payload.user.role;
  },
  RegisterFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.isAuthenticated = false;
    state.role = null;
  },

  LoadUserRequest: (state) => {
    state.loading = true;
  },
  LoadUserSuccess: (state, action) => {
    state.loading = false;
    state.user = action.payload;
    state.isAuthenticated = true;
    state.role = action.payload.role;
  },
  LoadUserFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.isAuthenticated = false;
    state.role = null;
  },

  LogoutUserRequest: (state) => {
    state.loading = true;
  },
  LogoutUserSuccess: (state) => {
    state.loading = false;
    state.user = null;
    state.isAuthenticated = false;
    state.role = null;

    //storage.removeItem("persist:root");
  },
  LogoutUserFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.isAuthenticated = true;
    state.role = action.payload.role;
  },

  updateProfileRequest: (state) => {
    state.loading = true;
  },
  updateProfileSuccess: (state, action) => {
    state.loading = false;
    state.message = action.payload;
  },
  updateProfileFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  updatePasswordRequest: (state) => {
    state.loading = true;
  },
  updatePasswordSuccess: (state, action) => {
    state.loading = false;
    state.message = action.payload;
  },
  updatePasswordFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
  MonthlyUpdateRequest: (state) => {
    state.loading = true;
  },
  MonthlyUpdateSuccess: (state, action) => {
    state.loading = false;
    state.message = action.payload;
  },
  MonthlyUpdateFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  deleteProfileRequest: (state) => {
    state.loading = true;
  },
  deleteProfileSuccess: (state, action) => {
    state.loading = false;
    state.message = action.payload;
  },
  deleteProfileFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  forgotPasswordRequest: (state) => {
    state.loading = true;
  },
  forgotPasswordSuccess: (state, action) => {
    state.loading = false;
    state.message = action.payload;
  },
  forgotPasswordFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  resetPasswordRequest: (state) => {
    state.loading = true;
  },
  resetPasswordSuccess: (state, action) => {
    state.loading = false;
    state.message = action.payload;
  },
  resetPasswordFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  sendConfirmUserRequest: (state) => {
    state.loading = true;
  },
  sendConfirmUserSuccess: (state, action) => {
    state.loading = false;
    state.message = action.payload;
  },
  sendConfirmUserFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  confirmUserRequest: (state) => {
    state.loading = true;
  },
  confirmUserSuccess: (state, action) => {
    state.loading = false;
    state.message = action.payload;
  },
  confirmUserFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  UserClearErrors: (state) => {
    state.error = null;
  },
  UserClearMessage: (state) => {
    state.message = null;
  },
});
export const passwordReducer = createReducer(initialState, {
  updatePasswordRequest: (state) => {
    state.loading = true;
  },
  updatePasswordSuccess: (state, action) => {
    state.loading = false;
    state.message = action.payload;
  },
  updatePasswordFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  forgotPasswordRequest: (state) => {
    state.loading = true;
  },
  forgotPasswordSuccess: (state, action) => {
    state.loading = false;
    state.message = action.payload;
  },
  forgotPasswordFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  resetPasswordRequest: (state) => {
    state.loading = true;
  },
  resetPasswordSuccess: (state, action) => {
    state.loading = false;
    state.message = action.payload;
  },
  resetPasswordFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  passwordClearErrors: (state) => {
    state.error = null;
  },
  passwordClearMessage: (state) => {
    state.message = null;
  },
});

export const KPIUpdateReducer = createReducer(initialState, {
  KPIUpdateRequest: (state) => {
    state.loading = true;
  },
  KPIUpdateSuccess: (state, action) => {
    state.loading = false;
    state.data = action.payload;
  },
  KPIUpdateFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.data = null;
  },
  KPIUpdateClearErrors: (state) => {
    state.error = null;
  },
  KPIUpdateClearMessage: (state) => {
    state.message = null;
  },
});

export const getKPIOptionsReducer = createReducer(initialState, {
  getKPIRequest: (state) => {
    state.loading = true;
  },
  getKPISuccess: (state, action) => {
    state.loading = false;
    state.KPIOptions = action.payload;
  },
  getKPIFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.KPIOptions = null;
  },
  getKPIClearErrors: (state) => {
    state.error = null;
  },
  getKPIClearMessage: (state) => {
    state.message = null;
  },
});
export const getKeyMetricsOptionsReducer = createReducer(initialState, {
  getKeyMetricsRequest: (state) => {
    state.loading = true;
  },
  getKeyMetricsSuccess: (state, action) => {
    state.loading = false;
    state.KeyMetricsOptions = action.payload;
  },
  getKeyMetricsFailure: (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.KeyMetricsOptions = null;
  },
  getKeyMetricsClearErrors: (state) => {
    state.error = null;
  },
  getKeyMetricsClearMessage: (state) => {
    state.message = null;
  },
});
